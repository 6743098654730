import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  typographyCustomStyle: {
    margin: "0px !important",
    padding: 0,
  },
  selectAccount: {
    minWidth: "120px"
  }
}));