import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "../../Panels/Validations/ApplyValidations/style";
import CodeReader from "../../CodeReader";
import TicketValidations from "../../TicketComponents/TicketValidations";
import { TICKET_SEARCH_TYPE } from "../../../constants";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import useHasPermissions from "../../../hooks/useHasPermissions";
import SelectableChip from "../../SelectableChip";
import { ToggleButton } from "@material-ui/lab";
import { useStyles as useValidateOnePassStyles } from "./style";

const ticketRegex =
  "^TicketID:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$";
const urlRegex =
  "^(https?://)?([A-Za-z-.]){1}(:[0-9]{2,5})?.*/mobilepay/[0-9A-Fa-f]{8}[-]?(?:[0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}";
const guidRegex =
  "[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}[})]?";

const ValidateOnePass = ({
  automaticallyApplyValidationEnabled,
  validationAccountOptions,
  facilityID,
  scopeAwareFacilityID,
  className,
}) => {
  const classes = useStyles();
  const validateOnePassClasses = useValidateOnePassStyles();
  const [isScanning, setIsScanning] = useState(false);
  const [parsedFromCamera, setParsedFromCamera] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const [
    isSendApplyValidationRunning,
    setIsSendApplyValidationRunning,
  ] = useState(false);
  const [clearInputField, setClearInputField] = useState(false);
  const [searchType, setSearchType] = useState(TICKET_SEARCH_TYPE.TICKET);
  const [selectedAccount, setSelectedAccount] = useState(
    validationAccountOptions?.length > 0
      ? validationAccountOptions[0]?.contractHolderID
      : ""
  );
  const isLPREnabled = useFeatureFlag("LPR");
  const onParsedFromCameraChange = (value) => setParsedFromCamera(value);

  const onSendApplyValidationRunningChange = (value) =>
    setIsSendApplyValidationRunning(value);

  const handleQRCodeRead = (readResult) => {
    if (!readResult || readResult === "") return;
    // first determine if the encoded value matches are predefined formats
    const tests = [new RegExp(ticketRegex, "i"), new RegExp(urlRegex, "i")];
    const validRegexers = tests.filter((regexer) => regexer.test(readResult));
    const regexer = validRegexers[0];
    if (!regexer) return;
    // snag the ticket id
    const ticketParseResult = new RegExp(guidRegex, "g").exec(readResult);
    if (!ticketParseResult) return;
    setParsedFromCamera(true);
    setSearchInput(ticketParseResult[0]);
    // Need to clear out search input in order
    // to accept ticket scanned twice
    setTimeout(() => {
      setSearchInput(null);
    }, 200);
  };

  const handleAccountSelection = (event) => {
    setSelectedAccount(event.target.value);
  };

  const resetValue = (searchType) => {
    setClearInputField(true);
    setSearchType(searchType);
  };

  return (
    <Card className={className}>
      <CardHeader className={classes.cardHeader} title="ONE Pass" />
      <CardContent className={classes.contentRoot}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className="create-one-pass-button">
              Create ONE Pass
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={validateOnePassClasses.typographyCustomStyle}
              variant="subtitle2"
            >
              -OR-
            </Typography>
            <Typography
              className={validateOnePassClasses.typographyCustomStyle}
            >
              Convert Ticket to ONE Pass
            </Typography>
          </Grid>
          {validationAccountOptions?.length > 1 && (
            <Grid item xs={12}>
              <InputLabel id="account-select-label">Account</InputLabel>
              <Select
                labelId="account-select-label"
                value={selectedAccount}
                onChange={handleAccountSelection}
                className={validateOnePassClasses.selectAccount}
              >
                {validationAccountOptions.map((validationAccount) => (
                  <MenuItem
                    key={validationAccount.contractHolderID}
                    value={validationAccount.contractHolderID}
                  >
                    {validationAccount.validationAccountName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={8} className="one-pass-code-reader">
            <CodeReader
              handleRead={handleQRCodeRead}
              handleError={(err) => {}}
              isScanning={isScanning}
              disabled={
                isSendApplyValidationRunning &&
                automaticallyApplyValidationEnabled
              }
              enableQROnly={true}
            />
          </Grid>
          <Grid item xs={6}>
            <ToggleButton
              classes={{
                root: classes.toggleButtonRoot,
                selected: classes.toggleButtonSelected,
              }}
              selected={isScanning}
              onChange={() => {
                setIsScanning((prev) => !prev);
              }}
              data-testid="toggle-button"
            >
              {isScanning ? "Stop Scanning" : "Scan Ticket"}
            </ToggleButton>
          </Grid>
          <Grid item xs={6} sm={8} className={classes.chipGrid}>
            <SelectableChip
              text="Ticket"
              variant="primary"
              onClick={() => resetValue(TICKET_SEARCH_TYPE.TICKET)}
              selected={searchType === TICKET_SEARCH_TYPE.TICKET}
              className="ticket-button"
            />
            {isLPREnabled && (
              <SelectableChip
                text="License Plate"
                variant="primary"
                onClick={() => resetValue(TICKET_SEARCH_TYPE.LICENSE_PLATE)}
                selected={searchType === TICKET_SEARCH_TYPE.LICENSE_PLATE}
                className="license-plate-button"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TicketValidations
              facilityID={facilityID}
              searchTermInput={searchInput}
              validationOffers={[]}
              autoApplyValidationOffer={false}
              itemLimit={50}
              isSendApplyValidationRunning={isSendApplyValidationRunning}
              handleSendApplyValidationRunningChange={
                onSendApplyValidationRunningChange
              }
              data-testid="ticket-validations"
              scopeAwareFacilityID={scopeAwareFacilityID}
              automaticallyApplyValidationEnabled={
                automaticallyApplyValidationEnabled
              }
              searchType={searchType}
              clearInputField={clearInputField}
              parsedFromCamera={parsedFromCamera}
              handleParsedFromCameraChange={onParsedFromCameraChange}
              isOnePassView={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ValidateOnePass;
