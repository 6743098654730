import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import apiClient from "../../../auth/apiClient";
import CountService from "../../../services/CountService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import OccupancySupplyForm from "./OccupancySupplyForm";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { Grid, Button, Divider, Typography } from "@material-ui/core";
import useAuthContext from "../../../hooks/useAuthContext";
import SettingsService from "../../../services/SettingsService";
import { useSelector, useDispatch } from "react-redux";
import { setEntityProperty } from "../../../state/slices/entities";
import {selectContextEntity, setEntityProperty as coreSetEntityProperty} from "../../../state/slices/CoreEntity";
import clsx from "clsx";
import { useStyles } from "./styles";
import OccupancyThemes from "./Thresholds/ThresholdColorPicker/OccupancyThemes";
import { Formik, Form } from "formik";
import ThresholdSettings from './Thresholds/ThresholdSetter/ThresholdSettings';
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const countService = new CountService(apiClient);
const settingsService = new SettingsService(apiClient);

const ContractAndTransientSum = (contract, transient) => {
  return contract + transient;
};

export const occupancySchema = Yup.object().shape({
  Contract: Yup.number()
    .min(0, "Value must be greater than or equal to 0.")
    .required("Must have a valid number.")
    .test("is-not-decimal", "Value cannot be a decimal.", (value) => (value + "").match(/^\d*\.{0}\d*$/)),
  Transient: Yup.number()
    .min(0, "Value must be greater than or equal to 0.")
    .required("Must have a valid number.")
    .test("is-not-decimal", "Value cannot be a decimal.", (value) => (value + "").match(/^\d*\.{0}\d*$/)),

  Facility: Yup.number()
    .min(1, "Value must be greater than or equal to 1.")
    .required("Must have a valid number.")
    .test("is-not-decimal", "Value cannot be a decimal.", (value) => (value + "").match(/^\d*\.{0}\d*$/))
    .test(
      "is-greater",
      "Facility Capacity needs to be greater than or equal to the sum of transient and contract spaces",
      function(value) {
        if (!value) return false;
        let { Contract, Transient } = this.parent;
        let summedValue = ContractAndTransientSum(Contract, Transient);
        return summedValue <= value;
      }
    ),
});

const TotalOccupancy = ({ entityID, onClose }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();
  const [occupancy, setOccupancy] = useState();
  const [selectedThreshold, setSelectedThreshold] = useState({})
  const useCoreEntitySlice = useCoreEntityContext();

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const emptyGuid = "00000000-0000-0000-0000-000000000000";

  const { authReducer } = useAuthContext();
  const [authState] = authReducer;
  const currentUserName = authState.userSession?.idToken?.payload?.email;

  const data = useSelector((state) =>useCoreEntitySlice ? selectContextEntity(state)?.settings?.find((x) => x.name == "preferences").value : state?.entities?.Context?.settings?.find((x) => x.name == "preferences").value);
  const settings = useSelector((state) =>useCoreEntitySlice ? selectContextEntity(state)?.settings : state?.entities?.Context?.settings);
  const dispatch = useDispatch();
  let preferencesData;
  const [prefData, setPrefData] = useState({
    preferencesData: {
      occupancythemes: [],
    },
  });

  const handleValueChange = (threshold) => {
    setSelectedThreshold({ ...threshold })
  }

  const [isSaving, setIsSaving] = useState(false);
  const saveForm = async (e) => {
    let createList = [];
    let updateList = [];

    setIsSaving(true)
    setDisabled(true);
    

    occupancy.forEach((row) => {
      row.totalSpaces = e[row.description];
      row.createdBy = row.createdBy == "" ? currentUserName : row.createdBy;
      row.createdDate = row.createdDate == "" ? new Date() : row.createdDate;

      if (row.occupancySupplyID == emptyGuid) {
        createList.push(row);
      } else {
        updateList.push(row);
      }
    });

    
    let success = false;
    if (createList.length > 0) {
      try {
        var response = await countService.createOccupancySupply(createList);
        if(response.status == 200) success = true;
      } catch {
        success = false;
        enqueueSnackbar("Failed to create occupancies.", {
          variant: "error", tag: "FailedToCreateOccupancies"
        });
      }
    }

    if (updateList.length > 0) {
      try {
        var response = await countService.UpdateOccupancySupply(updateList);
        if(response.status == 200) success = true;
      } catch {
        success = false;
        enqueueSnackbar("Failed to update occupancies.", {
          variant: "error", tag: "FailedToCreateOccupancies"
        });
      }
    }

    success = await savePreferenceData(success);
    success = await SaveThresholdData(success);

    setIsSaving(false);

    if(success) {
      enqueueSnackbar("Successfully updated occupancy availability and thresholds.", {
        variant: "success",
      });
      onClose(success);
    }    
  };

  const GetThresholdData = async (responseData) => {
    try {
      var result = await countService.GetThresholdsForEntity(entityID);
      let occupancyArray = responseData

      occupancyArray.forEach(row => {
        let index = result.data.findIndex(x => x.occupancyTypeId == row.occupancyTypeID)
        if(index < 0) {
          row.threshold = { entityId: row.entityID, highOff: 0, highOn: 0, lowOff: 0, lowOn: 0, occupancyTypeId: row.occupancyTypeID, thresholdsId: -1};
        } else {
          row.threshold = result.data[index]
        }
      })

      setOccupancy([ ...occupancyArray]);
    } catch (error) {
      if(error?.response?.status === 404) {
        return;
      }
      enqueueSnackbar("Failed to get thresholds", 
      { variant: "error", tag: "FailedToGetThresholds"})
    }
  }

  useEffect(() => {
    let responseData = [];

    countService.getOccupancyTypes()
      .then((TypeData) => {
        //Filtering out 'Valet Ticket' occupancy type as no related functionality applicable for Valet yet.
        TypeData.data = TypeData.data?.filter(x => x.description !== "Valet Ticket");
        countService.GetOccupancySupplyByEntity(entityID)
          .then((occupancyData) => {
            TypeData.data.forEach((type) => {
              if (occupancyData.data.find((x) => x.occupancyTypeID == type.occupancyTypeID) === undefined) {
                responseData.push({
                  entityID: entityID,
                  description: type.description,
                  occupancyTypeID: type.occupancyTypeID,
                  totalSpaces: 0,
                  createdBy: "",
                  createdDate: "",
                  occupancySupplyID: emptyGuid,
                });
              } else {
                responseData.push({
                  entityID: entityID,
                  description: type.description,
                  occupancyTypeID: type.occupancyTypeID,
                  totalSpaces: occupancyData.data.find((x) => x.occupancyTypeID == type.occupancyTypeID).totalSpaces,
                  createdBy: occupancyData.data.find((x) => x.occupancyTypeID == type.occupancyTypeID).createdBy,
                  createdDate: occupancyData.data.find((x) => x.occupancyTypeID == type.occupancyTypeID).createdDate,
                  occupancySupplyID: occupancyData.data.find((x) => x.occupancyTypeID == type.occupancyTypeID)
                    .occupancySupplyID,
                });
              }
              GetThresholdData(responseData)
            });

            setOccupancy(responseData);
            setIsLoading(false);
          })
          .catch((err) => {
            if (err.response.status === 404) {
              TypeData.data.forEach((type) =>
                responseData.push({
                  entityID: entityID,
                  description: type.description,
                  occupancyTypeID: type.occupancyTypeID,
                  totalSpaces: 0,
                  createdBy: "",
                  createdDate: "",
                  occupancySupplyID: emptyGuid,
                })
              );
              
              GetThresholdData(responseData);
              setIsLoading(false);
            } else {
              enqueueSnackbar("Failed to retreive occupancyData", {
                variant: "error", tag: "FailedToGetOccupancyData"
              });
            }
          });
      })
      .catch((err) => {
        enqueueSnackbar("Failed to retreive occupancyTypes", {
          variant: "error", tag: "FailedToGetOccupancyTypes"
        });
      });
  }, [entityID]);

  useEffect(() => {
    try {
      if (preferencesData != undefined || data != "{}") {
        preferencesData = JSON.parse(data);
        setPrefData(preferencesData);
      }
    } catch (err) {
      console.log("Error parsing JSON data.", err);
    }
  }, [data]);

  const savePreferenceData = async (success) => {
    let sliced = settings.map((x) => ({ ...x, value: x.name == "preferences" ? JSON.stringify(prefData) : x.value }));

    try {
      var response = await settingsService.updateSetting(entityID, { name: "setting.preferences", value: JSON.stringify(prefData) });
      if(response.status == 200) {
        dispatch(
          useCoreEntitySlice ?
            coreSetEntityProperty({
              entityid: entityID,
              property: "settings",
              value: sliced,
            })
            :
            setEntityProperty({
              entityid: entityID,
              property: "settings",
              value: sliced,
            })
        );
        success = true;
      }
    } catch  {
      success = false;
        enqueueSnackbar("Error saving preferences", 
        { variant: "error", tag: "ErrorSavingPreference" });
    }

    return success;
  };

  const SaveThresholdData = async (success) => {
    try {
      await countService.upsertThreshold([ selectedThreshold.threshold ]);     
    } catch {
      success = false;
      enqueueSnackbar("Error saving thresholds", 
      { variant: "error", tag: "ErrorSavingThresholds" });
    }

    return success;
  };

  const drawerButtons = (saveData, saveBtn = true, isSaving = false) => {
  return (
    <Grid item xs={12} md={12} lg={12} style={{ marginTop: 20 }} className={classes.containerPadding}>
      <Divider></Divider>
      <Button
        style={{ float: "right" }}
        startIcon={<CancelIcon />}
        className={clsx("cancelBtn", classes.btnControl)}
        data-id="cancelBtn"
        name="cancel"
        variant="contained"
        onClick={() => onClose(false)}
      >
        Cancel
      </Button>
      {saveBtn && (
        <Button
          style={{ float: "right" }}
          disabled={isSaving}
          startIcon={<SaveIcon />}
          color="primary"
          data-id="saveBtn"
          name="Save"
          variant="contained"
          className={clsx("saveBtn", classes.btnControl)}
          onClick={saveData}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      )}
    </Grid>
  );
};

  return (
      <Formik
        onSubmit={(e) => saveForm(e)}
        enableReinitialize
        validateOnChange={false}
        initialValues={{
          Facility: occupancy?.find((x) => x.description.toLowerCase() === "facility")?.totalSpaces,
          Contract: occupancy?.find((x) => x.description.toLowerCase() === "contract")?.totalSpaces,
          Transient: occupancy?.find((x) => x.description.toLowerCase() === "transient")?.totalSpaces,
        }}
        validationSchema={occupancySchema}
      >
        {({ submitForm }) => (
          <Form>
            <OccupancySupplyForm
              isLoading={isLoading}
              disabled={disabled}
            />

            <OccupancyThemes
              occupancyThemeData={prefData?.preferencesData?.occupancythemes}
              themeDataChange={(data) => {
                let curState = { ...prefData };
                curState.preferencesData.occupancythemes = data;

                setPrefData(curState);
              }}
            />
            <ThresholdSettings thresholdData={occupancy} handleValueChange={handleValueChange} />
            {drawerButtons((e) => submitForm(e), true, isSaving)}
          </Form>
        )}
      </Formik>
  );
};

export default TotalOccupancy;
